import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Marquee from "react-fast-marquee";

import * as classes from "../../styles/partners/partners.module.scss";
import '../../styles/partners/style.scss'
import softwareone from "../../assets/images/partners/logo/softwareone.png";
import lucernys from "../../assets/images/partners/logo/lucernys.png";
import avar from "../../assets/images/partners/logo/avar.png";
import tecfliar from "../../assets/images/partners/logo/tecfliar.png";
import recklabs from "../../assets/images/partners/logo/recklabs.png";
import globaldots from "../../assets/images/partners/logo/globaldots.png";
import bytes from "../../assets/images/partners/logo/bytes.png";
import metsi from "../../assets/images/partners/logo/metsi.png";
import behindEveryEloud from "../../assets/images/partners/logo/behind_every_cloud.png";
import metrixData from "../../assets/images/partners/logo/metrixData.png"

const PartnersLogo = () => {
  return (
    <>
    <section className={classes.partners__logo}>
      <Container>
        <Row className="justify-content-center">
          <Col sm={12} lg={5} xs={12} className="text-center p-0">
            <div className={classes.heading}>
              <h2>
                Our <span>trusted</span> partners
              </h2>
            </div>
            <div className={classes.sub_heading}>
              <span>
                We are working with leading cloud service providers and
                technology companies to deliver optimized cloud storage
                solutions to their clients.
              </span>
            </div>
          </Col>
        </Row>
        <Row className={classes.mobile__logo}>
          <Col xs={12}>
            <Marquee delay={2} autoFill={true} speed={35} gradient={false} pauseOnHover={true}>
              <img src={softwareone} alt="softwareone" />
              <img src={lucernys} alt="lucernys" />
              <img src={avar} alt="avar" />
              <img src={tecfliar} alt="tecfliar" />
              <img src={recklabs} alt="recklabs" />
              <img src={globaldots} alt="globaldots" />
              <img src={bytes} alt="bytes" />
              <img src={metsi} alt="metsi" />
              <img src={behindEveryEloud} alt="behindEveryEloud" />
              <img src={metrixData} alt="MetrixData 360 Grey " />
            </Marquee>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
};

export default PartnersLogo;
