import React from "react";
import PartnersLogo from "./PartnersLogo";
import Hero from "./Hero";
import WhyLucidity from "./WhyLucidity";
import WhoPartner from "./WhoPartner";
import Testimonial from "./Testimonial";
import PartnerExploreCards from "./PartnerExploreCards";

const PartnersComponent = () => {
    return (
        <>
            <Hero />
            <PartnersLogo />
            <WhyLucidity />
            <WhoPartner />
            <Testimonial />
            <PartnerExploreCards />
        </>
    );
};

export default PartnersComponent;
