import { Link } from "gatsby";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

import * as classTes from "../../styles/testimonials.module.scss";

import '../../styles/partners/style.scss'
import * as classes from "../../styles/partners/partners.module.scss";
// lottie files
import VideoCard from "../Testimonials/VideoCard";

const Testimonial = () => {
  return (
    <>
    <section
      className={`${classes.partners__testimonial} ${classTes.testimonials}`}
    >
      <Container className={classTes.container}>
        <Row className="justify-content-center">
          <Col lg={8} xs={12} className="text-center p-0">
            <div className={classes.heading}>
              <h2>
                Hear from the cloud <span>industry experts</span>
              </h2>
            </div>
          </Col>
        </Row>
        <div className={`${classTes.video_wrapper} ${classes.video_wrapper_big}`}>
          <Row className={classTes.testimonials__video_row}>
            <Col sm="4" className={classTes.testimonials__col}>
              <VideoCard
                videoCode="EJyw22grBI4"
                category="Partner"
                designation="Joan Kuehl, Ex CIO - Elevate, Travelers"
              />
            </Col>
            <Col sm="4" className={classTes.testimonials__col}>
              <VideoCard
                videoCode="bCeubtlLclY"
                category="Customer"
                designation="Kunal Dawn, VP Engg - Bobble AI"
              />
            </Col>
            <Col sm="4" className={classTes.testimonials__col}>
              <VideoCard
                videoCode="u8_byHmPEyw"
                category="Customer"
                designation="Jay Dhakar, Cloud Migration Architect - SpartanNash"
              />
            </Col>
          </Row>
        </div>
        <div className="video_carousal">
          <Carousel indicators={false} >
            <Carousel.Item>
              <VideoCard
                videoCode="EJyw22grBI4"
                category="Partner"
                designation="Joan Kuehl, Ex CIO - Elevate, Travelers"
              />
            </Carousel.Item>
            <Carousel.Item>
              <VideoCard
                videoCode="bCeubtlLclY"
                category="Customer"
                designation="Kunal Dawn, VP Engg - Bobble AI"
              />
            </Carousel.Item>
            <Carousel.Item>
              <VideoCard
                videoCode="u8_byHmPEyw"
                category="Customer"
                designation="Jay Dhakar, Cloud Migration Architect - SpartanNash"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <Link to="/testimonials" className={classes["watch_more"]}>
          <Button variant="outlined">
            Watch More
          </Button>
        </Link>
      </Container>
    </section>
    </>
  );
};

export default Testimonial;
