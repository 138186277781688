import React, { useContext, useState, memo } from "react";
import {
  Accordion,
  AccordionContext,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Lottie from "react-lottie-segments";
import Card from "react-bootstrap/Card";
import circelArrow from "../../assets/images/partners/circle_arrow.svg";
import * as finops from "../../assets/images/partners/finops.json";
import * as deploy from "../../assets/images/partners/deploy.json";
import * as cloud from "../../assets/images/partners/cloud.json";

import * as classes from "../../styles/partners/partners.module.scss";
import '../../styles/partners/style.scss'

const lottieSetting = (data) => {
  return {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
};

const WhoPartner = () => {
  const [currentEvent, setCurrentEvent] = useState(-1)
  function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
  
    const isCurrentEventKey = activeEventKey === eventKey;
    setCurrentEvent(activeEventKey)
    return (
      <div
        className={`toggle-header ${isCurrentEventKey ? `header-active` : ""}`}
        type="button"
        onClick={decoratedOnClick}
      >
        <img src={circelArrow} alt="plus" />
        {children}
      </div>
    );
  }
  return (
    <>
    <section className={classes.partners__who_partner}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={7} xs={12} className="text-center p-0">
            <div className={classes.heading}>
              <h2>
              Who <span>should partner</span> with us
              </h2>
            </div>
          </Col>
        </Row>
        <Row className={`justify-content-center ${classes.accordian}`}>
          <Col sm={12} lg={6}>
            <Accordion className="who_accordion" defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="0">FinOps Services</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    Enrich your offering with faster assessment and
                    differentiated solutions to deliver effortless ROI
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="1">
                    Managed Service Provider
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    Accelerate deployment, reduce time to ROI, and develop
                    differentiated cloud solutions for your clients
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="2">Cloud Tools Reseller</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    Expand your optimization product portfolio and experience
                    the NoOps for cloud storage
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          <Col sm={12} lg={5} md={6} className={classes.lottie}>
            {currentEvent === '0' && <Lottie options={lottieSetting(finops)} />}
            {currentEvent === '1' && <Lottie options={lottieSetting(deploy)} />}
            {currentEvent === '2' && <Lottie options={lottieSetting(cloud)} />}
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
};

export default memo(WhoPartner);
