import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie-segments";

import * as classes from "../../styles/partners/partners.module.scss";
// lottie files
import * as analyze from "../../assets/images/partners/analyze.json";
import * as vision from "../../assets/images/partners/vision.json";
import * as roi from "../../assets/images/partners/roi.json";

const lottieSetting = (data) => {
  return {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
};

const WhyLucidity = () => {
  return (
    <>
    <section className={classes.partners__why_lucidity}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} xs={12} className="text-center p-0">
            <div className={classes.heading}>
              <h2>
                Why <span>partner</span> with Lucidity?
              </h2>
            </div>
          </Col>

          <div className={classes.disk}>
            <Row className="justify-content-center text-center">
              <Col lg={12}>
                <Row className={classes.disk__spend}>
                  <Col sm={6} className={classes.col_one}>
                    <Lottie options={lottieSetting(analyze)} />
                  </Col>
                  <Col sm={6} className={classes.col_two}>
                    <div className={classes.inner_heading}>
                      <h2>
                        Simplify Cloud <span>storage Assessment </span>
                      </h2>
                    </div>
                    <span className={classes.sub_heading}>
                      Bring your assessment time down to a day, with <span className="text-primary">Lucidity
                      Storage Audit.</span> A free tool designed to analyze cloud
                      storage wastage and client’s savings potential. No
                      customer access required & no FinOps effort expected.
                    </span>
                  </Col>
                </Row>

                <Row className={classes.disk__waste}>
                  <Col sm={6} className={classes.col_one}>
                    <div className={classes.inner_heading}>
                      <h2>
                        Capture <span>untapped potential</span>
                      </h2>
                    </div>
                    <span className={classes.sub_heading}>
                      Achieve tangible ROI with <span className="text-primary">Lucidity Auto-Scaler.</span> Designed
                      to automate cloud storage, provisioning with just 3
                      clicks. No more implementation challenges or tech
                      bandwidth hassles. All it takes is 1hr/ week of DevOps
                      effort.
                    </span>
                  </Col>
                  <Col sm={6} className={classes.col_two}>
                    <Lottie options={lottieSetting(vision)} />
                  </Col>
                </Row>

                <Row className={classes.disk__downtime}>
                  <Col sm={6} className={classes.col_one}>
                    <Lottie options={lottieSetting(roi)} />
                  </Col>
                  <Col sm={6} className={classes.col_two_last}>
                    <div className={classes.inner_heading}>
                      <h2>
                        Improve Closure probability{" "}
                        <span>and facilitate renewal</span>
                      </h2>
                    </div>
                    <span className={classes.sub_heading}>
                      Be the leaders of cloud storage optimization with
                      <span className="text-primary"> Lucidity.</span> As cloud storage experts, we understand the
                      challenges you face in meeting customer expectations.
                      Simplify cloud cost optimization and deliver continued ROI
                      to keep customers satisfied.
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </section>
    </>
  );
};

export default WhyLucidity;
