import React from "react";
import Layout from "../../components/Common/Layout";
import Seo from "../../components/Common/Seo";
import PartnersComponent from "../../components/Partners";

const Partners = () => {
  return (
    <Layout>
      <Seo
        title="Partner with Lucidity | Differentiate your FinOps offering with Lucidity"
        description={`Description: Go beyond the obvious and pioneer cloud storage optimization for your customers with Lucidity`}
        canonicalUrl="https://www.lucidity.cloud/partners"
      />
      <PartnersComponent />
    </Layout>
  );
};

export default Partners;
