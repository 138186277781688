import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import * as classes from "../../styles/partners/partners.module.scss";
import Tick from "../../assets/images/partners/tick.svg";
import DemoLargeButton from "../Common/DemoLargeButton";

const PartnerExploreCards = () => {
  return (
    <>
    <section className={classes.partners__explore}>
      <Container>
        <Row className={classes.row}>
          <Col sm={6} className={classes.col_one}>
            <div className={classes.values}>
              <div className={classes.values__wrapper}>
                <div className={classes.heading}>
                  <h2>
                    Become a <span>Partner</span>
                  </h2>
                </div>
                <div className={classes.leaders}>
                  <span>
                    Schedule a personalized demo with our experts and take a
                    look as to how Lucidity can help you as a Partner
                  </span>
                </div>
                <div>
                  <div className={classes.leaders_points}>
                    <img src={Tick} alt="tick" />
                    <span>Reduce your Assessment time to 1 week</span>
                  </div>
                  <div className={classes.leaders_points}>
                    <img src={Tick} alt="tick" />
                    <span>Up to 70% in savings</span>
                  </div>
                </div>
                <div className={classes.buttons}>
                  <DemoLargeButton />
                </div>
              </div>
            </div>
          </Col>
          <Col sm={6} className={classes.col_two}>
            <div className={classes.values}>
              <div className={classes.values__wrapper}>
                <div className={classes.heading}>
                  <h2>
                    <span>Explore</span> more!
                  </h2>
                </div>
                <div className={classes.leaders}>
                  <span>
                    Check out how our partner won a leading National Bank and
                    revolutionized cloud storage optimization for them
                  </span>
                </div>
                <div>
                  <div className={classes.leaders_points}>
                    <img src={Tick} alt="tick" />
                    <span>65% yearly storage savings</span>
                  </div>
                  <div className={classes.leaders_points}>
                    <img src={Tick} alt="tick" />
                    <span>1 week to actionable insights</span>
                  </div>
                </div>
                <div className={classes.buttons}>
                  <a
                    href="https://www.get.lucidity.cloud/download"
                    target="_blank"
                  >
                    <Button
                      variant="outlined"
                      className={classes["download_now"]}
                    >
                      Download Now!
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
};

export default PartnerExploreCards;
