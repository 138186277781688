import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link } from "gatsby";
import Tick from "../../assets/images/audit/tick.svg";
import * as classes from "../../styles/partners/partners.module.scss";

const Hero = () => {
  return (
    <>
    <section className={classes.partners__hero}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} xs={12} className="text-center p-0">
            <div className={`${classes["heading"]} justify-content-center`}>
              <h1>
                <span>Partner</span> with Lucidity
              </h1>
            </div>
            <div className={classes.subheading}>
              <span>
                Pioneer cloud storage optimization for your customers with
                Lucidity
              </span>
            </div>
            <div className={`${classes["features"]} justify-content-center`}>
              <div>
                <div className={classes.inner_flex}>
                  <img src={Tick} alt="tick" />
                  <span>
                    Up to <span>70% savings</span>
                  </span>
                </div>
                <p>Go beyond the obvious in Cloud Storage</p>
              </div>

              <div>
                <div className={classes.inner_flex}>
                  <img src={Tick} alt="tick" />
                  <span>
                    <span>1 week to</span> insights
                  </span>
                </div>
                <p>Simplify assessment & provide tangible ROI</p>
              </div>
              <div>
                <div className={classes.inner_flex}>
                  <img src={Tick} alt="tick" />
                  <span>
                    <span>No</span> Engineering effort
                  </span>
                </div>
                <p>Achieve your promised ROI with minimal engineering effort</p>
              </div>
            </div>
              <Link to="/book-demo" className={classes["partner_button"]}>
                <Button
                  variant="outlined"
                >
                  Become a Partner
                </Button>
              </Link>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
};

export default Hero;
