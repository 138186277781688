// extracted by mini-css-extract-plugin
export var accordian = "partners-module--accordian--lEQAT";
export var buttons = "partners-module--buttons--cni0o";
export var col_one = "partners-module--col_one--XNSvV";
export var col_two = "partners-module--col_two--J-j-D";
export var col_two_last = "partners-module--col_two_last--zfjHA";
export var disk = "partners-module--disk---WqYR";
export var disk__downtime = "partners-module--disk__downtime--1K+4B";
export var disk__spend = "partners-module--disk__spend--Adzeh";
export var disk__waste = "partners-module--disk__waste--DibPn";
export var download_now = "partners-module--download_now--ZPJi8";
export var features = "partners-module--features--FBu7v";
export var heading = "partners-module--heading--G1ynU";
export var inner_flex = "partners-module--inner_flex--Wrwlj";
export var inner_heading = "partners-module--inner_heading--FDDaV";
export var leaders = "partners-module--leaders--PXrxx";
export var leaders_points = "partners-module--leaders_points--dRDW+";
export var logo_wraper = "partners-module--logo_wraper--eeb2b";
export var lottie = "partners-module--lottie--eLsOw";
export var mobile__logo = "partners-module--mobile__logo--zyTJv";
export var partner_button = "partners-module--partner_button--sj+lI";
export var partners__explore = "partners-module--partners__explore--oNP51";
export var partners__hero = "partners-module--partners__hero--vvzWf";
export var partners__logo = "partners-module--partners__logo--ISNwi";
export var partners__testimonial = "partners-module--partners__testimonial--qCd-B";
export var partners__who_partner = "partners-module--partners__who_partner--RMLT1";
export var partners__why_lucidity = "partners-module--partners__why_lucidity--sQEP8";
export var row = "partners-module--row--LsvVf";
export var sub_heading = "partners-module--sub_heading--0KlES";
export var subheading = "partners-module--subheading--MIZ7x";
export var values = "partners-module--values--UFtdC";
export var values__wrapper = "partners-module--values__wrapper---39fs";
export var video_wrapper_big = "partners-module--video_wrapper_big--b1eZ0";
export var watch_more = "partners-module--watch_more--xGPTO";